
import config from '../config/config';
import React, { Component } from 'react';
// import { a } from 'react-router-dom'
export default class Header extends Component {
  render() {
    return (
      <>

        <header id="site-header" className="site-header header-style-3 sticky-header">
          <nav class="navbar navbar-expand-lg octf-main-header">
            <div class="container-fluid">
              <div id="site-logo" className="site-logo">
                <a href={`${config.baseUrl}`}>
                  <img src="images/ESP LOGO NEW-2.png" alt="Engitech" className />
                </a>
              </div>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fa fa-bars navbar-toggler-icon" aria-hidden="true"></i>
              </button>
              <div class="collapse navbar-collapse main-navigation" id="navbarSupportedContent">
                <ul class="navbar-nav menu" style={{ justifyContent: "end" }}>
                  <li class="nav-item">
                    <a href={`${config.baseUrl}`} className="nav-a active">Home</a>
                  </li>
                  <li className='nav-item'><a href={`${config.baseUrl}About`} className="nav-a">About us</a></li>
                  {/* <li className='nav-item'><a href={`${config.baseUrl}Our_team`} className="nav-a">Our team</a></li> */}
                  {/* <li class="nav-item">
                      <a href="#" className='nav-a active'>Company</a>
                  <ul className="sub-menu">
                                      
                  </ul>
                    </li> */}
                  <li className="nav-item">
                    <a href="javascript:void(0)" className='nav-a'>Services</a>
                    <ul className="sub-menu">
                      <li className><a href={`${config.baseUrl}Blockchain_service`}>Blockchain Development</a>
                      </li>
                      <li className><a href={`${config.baseUrl}Nft_development`}>Nft Development</a>
                      </li>
                      <li className><a href={`${config.baseUrl}Defi_development`}>Defi Development</a>
                      </li>
                      <li className><a href={`${config.baseUrl}Web_development`}>Web Development</a>
                      </li>
                      <li className><a href={`${config.baseUrl}Mobile_development`}>Mobile Development</a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a href="javascript:void(0)" className='nav-a'>Portfolio</a>
                    <ul class="sub-menu">
                      <li><a href={`${config.baseUrl}Portfolio_blockchain`}>Blockchain Development</a></li>
                      <li><a href={`${config.baseUrl}Portfolio_web`}>Website Development</a></li>
                      <li><a href={`${config.baseUrl}Portfolio_mobile`}>Mobile Development</a></li>
                    </ul>
                  </li>
                  {/* <li class="nav-item">
                    <a href={`${config.baseUrl}Blog`} className="nav-a">Blog</a>
                    </li> */}
                  <li className='nav-item'>
                    <a href={`${config.baseUrl}Contact`} className="nav-a">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          {/* <div className="octf-main-header">
            <div className="octf-area-wrap">
              <div className="container octf-mainbar-container">
                <div className="octf-mainbar">
                  <div className="octf-mainbar-row octf-row">
                    <div className="octf-col logo-col">
                      <div id="site-logo" className="site-logo">
                        <a href={`${config.baseUrl}`}>
                          <img src="images/logo1.png" alt="Engitech" className />
                        </a>
                      </div>
                    </div>
                    <div className="octf-col menu-col">
                      <nav id="site-navigation" className="main-navigation">
                        <ul className="menu">
                          <li className=" current-menu-item">
                            <a href={`${config.baseUrl}`} >Home</a>
                          </li>
                          <li className="menu-item-has-children  ">
                            <a href="#">Company</a>
                            <ul className="sub-menu">
                              <li><a href={`${config.baseUrl}About`}>About us</a></li>
                              <li><a href={`${config.baseUrl}Our_team`}>Our team</a></li>
                            </ul>
                          </li>
                          <li className="menu-item-has-children">
                            <a href="">Services</a>
                            <ul className="sub-menu">
                              <li className><a href={`${config.baseUrl}Blockchain_service`}>Blockchain Development</a>
                              </li>
                              <li className><a href={`${config.baseUrl}Nft_development`}>Nft Development</a>
                              </li>
                              <li className><a href={`${config.baseUrl}Defi_development`}>Defi Development</a>
                              </li>
                              <li className><a href={`${config.baseUrl}Web_development`}>Web Development</a>
                              </li>
                              <li className><a href={`${config.baseUrl}Mobile_development`}>Mobile Development</a>
                              </li>
                            </ul>
                          </li>
                          <li className="menu-item-has-children">
                            <a href="">Portfolio</a>
                            <ul class="sub-menu">
                              <li><a href={`${config.baseUrl}Portfolio_blockchain`}>Blockchain development</a></li>
                              <li><a href={`${config.baseUrl}Portfolio_web`}>Web development </a></li>
                              <li><a href={`${config.baseUrl}Portfolio_mobile`}>Mobile development</a></li>
                            </ul>
                          </li>
                          <li className>
                            <a href={`${config.baseUrl}Blog`}>Blog</a>
                          </li>
                          <li><a href={`${config.baseUrl}Contact`}>Contact</a></li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          */}
        </header>

      </>

    )
  }
}
